import { CheckmarkIcon } from '..';
interface Props {
  checked?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  label?: string;
  className?: string;
}
const CheckmarkIconButton = ({
  checked,
  disabled = false,
  onClick,
  label,
}: Props) => {
  return (
    <div className={`flex w-fit cursor-pointer items-center`} onClick={onClick}>
      <button
        disabled={disabled}
        className={`flex min-h-[1.6rem] min-w-[1.6rem] items-center justify-center rounded-[.5rem] transition-colors focus:border-none focus:outline focus:outline-2 focus:outline-brand-primary ${
          checked ? 'bg-brand-secondary' : 'border-2 border-brand-dark-gray'
        }`}
        type="button"
      >
        {checked && <CheckmarkIcon height={8} width={12} variant="white" />}
      </button>
      {label && (
        <div className="ml-[1rem] select-none whitespace-nowrap">{label}</div>
      )}
    </div>
  );
};

export default CheckmarkIconButton;
